// define the scholarship object
export function Scholarshp(args) {
  args = args || {};
  this.ScholarshipName = args.ScholarshipName || "";
  this.Student = args.Student || "";
  this.ScholarshipURL = args.ScholarshipURL || "";
  this.ScholarshipApplicationURL = args.ScholarshipApplicationURL || "";
  this.Description = args.Description || "";
  this.Note = args.Note || "";
  this.RewardAmmount = args.RewardAmmount || 0;
  this.AutoRenew = args.AutoRenew || "";
  this.Deadline = args.Deadline || "";
  this.Announcement = args.Announcement || "";
  this.Status = args.Status || "";
  this.ScholarshipOpen = args.ScholarshipOpen || "";
  this.Won = args.Won || "";
  this.ReminderDate = args.ReminderDate || "";
  this.Reapply = args.Reapply || false;
  this.Resume = args.Resume || false;
  this.CollegeAcceptProof = args.CollegeAcceptProof || false;
  this.Headshot = args.Headshot || false;
  this.SentTo = args.SentTo || null;
  this.Transcript = args.Transcript || false;
}
