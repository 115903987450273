import moment from "moment";
import dayjs from "dayjs";
import { Scholarshp } from "./filter";
// import CSV file
import { headers, CsvFileName } from "data/controlsBarConstants";

export const toPrimitive = (data, objectId = false, reminder = false) => {
  let filteredData = new Scholarshp(data);
  let tmp = {};
  const AutoRenew = filteredData?.AutoRenew.join(",");
  const Deadline = dayjs(filteredData.Deadline).format("MM-DD-YYYY hh:mm:ss");
  const Announcement =
    filteredData.Announcement !== ""
      ? dayjs(filteredData.Announcement).format("MM-DD-YYYY hh:mm:ss")
      : null;
  const ScholarshipOpen =
    filteredData.ScholarshipOpen !== ""
      ? dayjs(filteredData.ScholarshipOpen).format("MM-DD-YYYY hh:mm:ss")
      : null;
  Object.assign(
    tmp,
    filteredData,
    { id: new Date().valueOf() },
    { AutoRenew: AutoRenew },
    { Deadline: Deadline },
    { Announcement: Announcement },
    { ScholarshipOpen: ScholarshipOpen },
    { Reminder: `${reminder}` },
    { ReminderDate: filteredData.ReminderDate }
  );
  if (objectId && objectId !== "") {
    Object.assign(tmp, { objectId: objectId });
  }

  return tmp;
};

export const toEditor = (schData) => {
  let tmp = [];
  for (let [key, value] of Object.entries(schData)) {
    switch (key) {
      case "Deadline":
        value = new Date(value);
        value = moment(value).format("YYYY-MM-DD");
        value = dayjs(value, "YYYY-MM-DD");
        break;
      case "Announcement":
      case "ScholarshipOpen":
        if (value !== "" && value) {
          value = new Date(value);
          value = moment(value).format("YYYY-MM-DD");
          value = dayjs(value, "YYYY-MM-DD");
        }
        break;
      case "AutoRenew":
        value = value.split(",");
        break;
      default:
        break;
    }
    if (value !== "" && value) tmp.push({ name: key, value: value });
  }
  return tmp;
};

export const toCsv = (data) => {
  let newData = [];
  for (const objItem of data) {
    let Deadline = moment(objItem.Deadline).format("MMM DD, YYYY  hh:mm:ss");
    let created = moment(objItem.created).format("MMM DD, YYYY  hh:mm:ss");
    let updated = moment(objItem.updated).format("MMM DD, YYYY  hh:mm:ss");
    newData.push(
      Object.assign(
        {},
        objItem,
        { Deadline: Deadline },
        { created: created },
        { updated: updated }
      )
    );
  }
  return {
    data: newData,
    headers: headers,
    filename: CsvFileName,
  };
};

export const toProfileEditor = (userData) => {
  let tmp = [];
  for (let [key, value] of Object.entries(userData)) {
    switch (key) {
      case "birthday":
        value = new Date(value);
        value = moment(value).format("YYYY-MM-DD");
        value = dayjs(value, "YYYY-MM-DD");
        break;
      default:
        break;
    }
    tmp.push({ name: key, value: value });
  }
  return tmp;
};
export const toProfilePrimitive = (prevUserData, updateUserData) => {
  let tmp = {};
  const birthday = dayjs(updateUserData.birthday).format("MM-DD-YYYY hh:mm:ss");
  Object.assign(tmp, prevUserData, updateUserData, { birthday: birthday });
  return tmp;
};
