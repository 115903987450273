import React, { useEffect, useRef, useState } from "react";
import DashboardContent from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { readSch } from "store";
import EditorModal from "./EditorModal";
import ProfileModal from "./ProfileModal";
import SettingModal from "./SettingModal";
import { FloatButton, Layout } from "antd";

import Header from "layouts/Header";
import Footer from "layouts/Footer";
import moment from "moment";
import { sendAnnouncementNotification } from "store";
const { Content } = Layout;

const Dashboard = ({ loading }) => {
  const dispatch = useDispatch();

  const ref = useRef(0);

  const userData = useSelector((state) => state.auth.currentUser);
  const allSchData = useSelector((state) => state.scholarshp);
  // open Edit and Add Sch
  const [openEditor, setOpenEditor] = useState(false);
  const [editorTitle, setEditorTitle] = useState("");
  const showEditorModal = (title) => {
    setOpenEditor(true);
    setEditorTitle(title);
  };
  // open Profile
  const [openProfile, setOpenProfile] = useState(false);
  const showProfileModal = () => {
    setOpenProfile(true);
  };
  // open Account Setting
  const [accountSetting, setAccountSetting] = useState(false);
  const showAccountSettingModal = () => {
    setAccountSetting(true);
  };
  const [schData, setSchData] = useState({});
  const handleEditorSch = (data) => {
    setSchData(data);
    showEditorModal("Edit Scholarship");
  };

  const handleDuplicateSch = (data) => {
    const allScholarshipNames = allSchData.map((item) => item.ScholarshipName);
    const duplicatedData = { ...data };

    delete duplicatedData.objectId;
    delete duplicatedData.Deadline;
    delete duplicatedData.Student;
    duplicatedData.ScholarshipName = renameItem(
      allScholarshipNames,
      data.ScholarshipName
    );

    setSchData(duplicatedData);
    showEditorModal("Duplicate Scholarship");
  };

  useEffect(() => {
    dispatch(readSch());
  }, [dispatch]);

  useEffect(() => {
    if (allSchData.length !== 0 && ref.current === 0) {
      allSchData.forEach((scholarship) => {
        if (scholarship.Reminder) {
          const today = Date.now();
          const reminderDate = scholarship.ReminderDate?.substr(0, 1);

          if (!!scholarship.Announcement && today <= scholarship.Announcement) {
            const timeDifference = scholarship.Announcement - today;
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
            if (daysDifference < reminderDate) {
              dispatch(
                sendAnnouncementNotification({
                  email: userData.email,
                  scholarshipName: scholarship.ScholarshipName,
                  announcement: moment(scholarship.Announcement).format(
                    "YYYY-MM-DD"
                  ),
                })
              );
            }
          }
        }
      });
    }
    ref.current++;
  }, [allSchData]);

  return (
    <>
      <Header userName={userData?.name} />
      {!loading && (
        <Content className="content-container" id="primaryLayout">
          <DashboardContent
            showEditorModal={showEditorModal}
            showProfileModal={showProfileModal}
            handleEditorSch={handleEditorSch}
            handleDuplicateSch={handleDuplicateSch}
            showAccountSettingModal={showAccountSettingModal}
            allSchData={allSchData}
          />
          <EditorModal
            user={userData}
            openEditor={openEditor}
            setOpenEditor={setOpenEditor}
            title={editorTitle}
            schData={schData}
            setSchData={setSchData}
          />
          <ProfileModal
            openProfile={openProfile}
            setOpenProfile={setOpenProfile}
            userData={userData}
          />
          <SettingModal
            accountSetting={accountSetting}
            setAccountSetting={setAccountSetting}
            userData={userData}
          />
          <FloatButton.BackTop
            className="float-btn"
            target={() => document.querySelector("#primaryLayout")}
          />
        </Content>
      )}
      <Footer />
    </>
  );
};

function renameItem(existingNames, newName) {
  console.log(existingNames, "existingNames");
  if (!existingNames.includes(newName)) {
    return newName;
  }

  const regex = /(.+?)(\s\((\d+)\))?$/;
  const matches = newName.match(regex);

  let baseName = matches[1];
  let number = matches[3] ? parseInt(matches[3]) : 0;

  while (existingNames.includes(newName)) {
    number += 1;
    newName = `${baseName} (${number})`;
  }
  console.log(newName, "newName");
  return newName;
}

export default Dashboard;
